const css = `@charset "UTF-8";
.custom-bullet {
  list-style: none;
  margin-bottom: 0;
}

.custom-bullet li::before {
  content: "•";
  color: #fcd703;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.course-bullet {
  list-style: none;
  margin-bottom: 0;
}

.course-bullet li::before {
  content: "•";
  color: #fcd703;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -4em;
}

.remove-bullet li::before {
  content: "•";
  color: white;
  margin-left: -5em;
}

.imported-courses-list {
  margin: 0;
  list-style: none;
}

.export-link {
  float: right;
  margin-top: 1em;
  text-decoration: underline;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9hcHBzL2RhdGEtaW5nZXN0LXVpL3NyYy9hcHAvY29udGFpbmVycy9QU0RhdGFJbXBvcnRKb2JTdW1tYXJ5Iiwic291cmNlcyI6WyJzdHlsZXMuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiO0FBQUE7RUFDRTtFQUNBOzs7QUFHRjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7O0FBR0Y7RUFDRTtFQUNBOzs7QUFHRjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7OztBQUdGO0VBQ0U7RUFDQTs7O0FBR0Y7RUFDRTtFQUNBO0VBQ0EiLCJzb3VyY2VzQ29udGVudCI6WyIuY3VzdG9tLWJ1bGxldCB7XG4gIGxpc3Qtc3R5bGU6IG5vbmU7XG4gIG1hcmdpbi1ib3R0b206IDA7XG59XG5cbi5jdXN0b20tYnVsbGV0IGxpOjpiZWZvcmUge1xuICBjb250ZW50OiAnXFwyMDIyJztcbiAgY29sb3I6ICNmY2Q3MDM7XG4gIGZvbnQtd2VpZ2h0OiBib2xkO1xuICBkaXNwbGF5OiBpbmxpbmUtYmxvY2s7XG4gIHdpZHRoOiAxZW07XG4gIG1hcmdpbi1sZWZ0OiAtMWVtO1xufVxuXG4uY291cnNlLWJ1bGxldCB7XG4gIGxpc3Qtc3R5bGU6IG5vbmU7XG4gIG1hcmdpbi1ib3R0b206IDA7XG59XG5cbi5jb3Vyc2UtYnVsbGV0IGxpOjpiZWZvcmUge1xuICBjb250ZW50OiAnXFwyMDIyJztcbiAgY29sb3I6ICNmY2Q3MDM7XG4gIGZvbnQtd2VpZ2h0OiBib2xkO1xuICBkaXNwbGF5OiBpbmxpbmUtYmxvY2s7XG4gIHdpZHRoOiAxZW07XG4gIG1hcmdpbi1sZWZ0OiAtNGVtO1xufVxuXG4ucmVtb3ZlLWJ1bGxldCBsaTo6YmVmb3JlIHtcbiAgY29udGVudDogJ1xcMjAyMic7XG4gIGNvbG9yOiB3aGl0ZTtcbiAgbWFyZ2luLWxlZnQ6IC01ZW07XG59XG5cbi5pbXBvcnRlZC1jb3Vyc2VzLWxpc3Qge1xuICBtYXJnaW46IDA7XG4gIGxpc3Qtc3R5bGU6IG5vbmU7XG59XG5cbi5leHBvcnQtbGluayB7XG4gIGZsb2F0OiByaWdodDtcbiAgbWFyZ2luLXRvcDogMWVtO1xuICB0ZXh0LWRlY29yYXRpb246IHVuZGVybGluZTtcbn1cbiJdfQ== */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
