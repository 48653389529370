import { Checkbox, Tooltip } from 'antd';
import React, { useEffect, useState, useRef } from 'react';

import apiClient from '../../utils/apiClient';
import Col from '../../components-v2/Col';
import type { NavSchoolsType, FrequencyNames, FrequencyDefinition } from '../../../../../../libs/common-interfaces';
import {
  DataUpdateFrequency,
  FrequencyOptions,
} from '../../components-v2/PSDataImportStudentRecords/dataUpdateFrequency';
import Row from '../../components-v2/Row';
import SelectSchools from '../../components-v2/SelectSchools';
import Wizard, { WizardProps } from '../../components-v2/Wizard';
import { showNotification, NotificationTypes } from '../../components/Notifications';
import { SISCourseImportTypes } from '../../../../../../libs/common-interfaces/constants';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { IntegrationContext } from '../../utils/context';
import { discardMappedCleverRoles, buildRolesFromConfig } from '../../utils/utils';
import { featureFlags } from '../../utils/featureFlags';

interface SisSyncSettingsProps extends WizardProps {
  frequencies: FrequencyOptions;
  defaultFrequency?: FrequencyNames;
  shouldInactivateRecords?: boolean;
  overwriteNavData?: boolean;
  shouldOverwriteCourseName?: boolean;
  doNotOverwriteEmail?: boolean;
  doNotInactivateCleverRoles?: string[];
  doNotMarkCleverStudentInactive?: boolean;
  flagNames: string[];
  setFlagCallees: ((value: any) => void)[];
  type: string;
  translateConfigEndPoint?: string;
  showRadio?: boolean;
  includeSchools?: boolean;
  includeInactivateRule?: boolean;
  includeOverwriteRule?: boolean;
  includeOverwriteRoleRule?: boolean;
  includeMappedRole?: boolean;
  includeOverwriteEmailRule?: boolean;
  includeCurrentCourseData?: boolean;
  fetchAvailableSchoolMappings?: boolean;
  overwriteCurrentCourseData?: boolean;
  includeHistoryCourseData?: boolean;
  overwriteHistoryCourseData?: boolean;
  includeMarkCleverStudentInactiveRule?: boolean;
  includeOverwriteCourseNameRule?: boolean;
}

interface IngressResponseData {
  cron?: FrequencyNames;
  frequency?: FrequencyDefinition;
}

const SisSyncSettings = (props: SisSyncSettingsProps): React.ReactElement => {
  const isMountedRef = useRef(null);
  const {
    frequencies,
    defaultFrequency,
    showRadio,
    type,
    overwriteNavData,
    shouldInactivateRecords,
    shouldOverwriteCourseName,
    doNotOverwriteEmail,
    doNotMarkCleverStudentInactive,
    doNotInactivateCleverRoles,
    includeMappedRole,
    flagNames,
    setFlagCallees,
    fetchAvailableSchoolMappings,
    overwriteCurrentCourseData,
    overwriteHistoryCourseData,
  } = props;
  const [pageLoading, setPageLoading] = useState(true);
  const [disableFooter, setHideFooterComp] = React.useState(true);
  const [originalConfig, setOriginalConfig] = useState({ importRules: {} });
  const [schools, setSchools] = useState([]);
  const [frequency, setFrequency] = useState<FrequencyDefinition>({ name: defaultFrequency });
  const [availableMappedSchools, setAvailableMappedSchools] = useState([]);
  const [cleverRoleOptions, setCleverRoleOptions] = useState([]);
  const [translateConfig, setTranslateConfig] = useState({});
  const integrationContext = React.useContext(IntegrationContext);

  const text =
    'Because Clever does not support alumni students, select this option so grade 12 and postgraduate students remain active in Naviance, even if they are removed from Clever. When alumni are active, their data will be included in Naviance Reports and they will continue to have access to Naviance Student.';

  function handleIngressFrequencyResponse(data: IngressResponseData) {
    const currentFrequency: FrequencyDefinition = data.frequency ?? { name: data.cron ?? defaultFrequency };
    setFrequency(currentFrequency);
  }

  const save = async (saveAndContinue?: boolean) => {
    try {
      await saveIngress();
      await saveConfig();

      if (saveAndContinue) {
        props.continueFn();
      } else {
        showNotification(NotificationTypes.success, 'Successfully Saved Settings.', '');
        props.saveFn();
      }
    } catch (err) {
      showNotification(NotificationTypes.error, 'Error Saving Settings', 'Server Error');
    }
  };
  const saveAndClose = async () => {
    try {
      await saveIngress();
      await saveConfig();
      showNotification(NotificationTypes.success, 'Successfully Saved Settings.', '');

      props.saveFn();
      props.cancelFn();
    } catch (err) {
      showNotification(NotificationTypes.error, 'Error Saving Settings', 'Server Error');
    }
  };

  const fetchIngress = async () => {
    try {
      // use this flag to determine if we should use the new dragon student processors
      const useModernDragonStudentProcessors = !!featureFlags['feature.dataIngest.modernDragonStudents'];
      const { data } = await apiClient.get(
        `/data-ingest/ingress/${type}?includeSchools=${props.includeSchools === true
        }&useModernDragonStudentProcessors=${useModernDragonStudentProcessors}`,
      );
      if (isMountedRef.current) {
        handleIngressFrequencyResponse(data);
        if (type != SISCourseImportTypes.COURSE_CATALOG) setSchools(data.schools || []);
      }
    } catch (error) {
      showNotification(
        NotificationTypes.error,
        'Error Getting SIS Import Settings',
        'Failure in getting data from server.',
      );
    }
  };

  const fetchNavianceRoles = async () => {
    try {
      let districtRoles, schoolRoles;
      if (
        !(translateConfig as any).valueMappings?.navianceSchoolRolesMapping ||
        !(translateConfig as any).valueMappings?.navianceDistrictRolesMapping
      ) {
        // fetch district and school based roles from Naviance
        [districtRoles, schoolRoles] = await Promise.all([
          apiClient.get(`/roles/userroles?userType=district`),
          apiClient.get(`/roles/userroles?userType=school`),
        ]);
      } else {
        // If roles are already available in translate config build the roles data from config instead of making api calls
        [districtRoles, schoolRoles] = [
          buildRolesFromConfig((translateConfig as any).valueMappings?.navianceDistrictRolesMapping),
          buildRolesFromConfig((translateConfig as any).valueMappings?.navianceSchoolRolesMapping),
        ];
      }

      const defaultTeacherRolePresent = schoolRoles.data.some((role) => role.name === 'Teacher (D)');
      if (!defaultTeacherRolePresent) {
        schoolRoles.data.push({ name: 'Teacher (D)' });
      }

      // Merge the roles into a single array post discarding the mapped roles
      const options = [
        ...discardMappedCleverRoles(
          districtRoles.data,
          (translateConfig as any).valueMappings?.districtRolesMappingCodes,
        ),
        ...discardMappedCleverRoles(schoolRoles.data, (translateConfig as any).valueMappings?.schoolRolesMappingCodes),
      ];
      if (isMountedRef.current) {
        setCleverRoleOptions(options);
      }
    } catch (error) {
      showNotification(NotificationTypes.error, 'Error Getting Naviance Roles', 'Failure in getting data from server.');
    }
  };

  const fetchConfig = async () => {
    try {
      let configType = type;
      if (type === 'students') {
        configType = '';
      }
      const configEndpoint =
        props.translateConfigEndPoint ?? `/data-ingest/sis/parameter-group/${configType}translateConfig`;
      const { data } = await apiClient.get(configEndpoint);
      if (type === SISCourseImportTypes.COURSE_CATALOG && data.hasOwnProperty('mappedSchools'))
        setSchools(data.mappedSchools);

      if (isMountedRef.current) {
        setOriginalConfig(data);
        setFlagCallees.forEach((fn, index) => {
          data.importRules?.[flagNames[index]] !== undefined && fn(data.importRules?.[flagNames[index]]);
        });
        setTranslateConfig(data.translateConfig);
      }
    } catch (error) {
      showNotification(NotificationTypes.error, 'Error Getting Import Rules', 'Failure in getting data from server.');
    }
  };

  const saveIngress = async () => {
    // use this flag to determine if we should use the new dragon student processors
    const useModernDragonStudentProcessors = !!featureFlags['feature.dataIngest.modernDragonStudents'];
    const { data } = await apiClient.patch(`/data-ingest/ingress/${type}`, {
      cron: frequency.name,
      frequency: frequency,
      schools: props.includeSchools ? schools : undefined,
      type: type,
      useModernDragonStudentProcessors,
    });
    handleIngressFrequencyResponse(data);
  };

  const saveConfig = async () => {
    let configType = type;
    if (type === 'students') {
      configType = '';
    }
    const configEndpoint =
      props.translateConfigEndPoint ?? `/data-ingest/sis/parameter-group/${configType}translateConfig`;
    const configCopy = { ...originalConfig };
    if (configType === SISCourseImportTypes.COURSE_CATALOG) configCopy['mappedSchools'] = schools;

    const { data } = await apiClient.patch(configEndpoint, {
      ...configCopy,
      importRules: {
        ...configCopy.importRules,
        overwriteNavData: overwriteNavData,
        overwriteCurrentCourseData: overwriteCurrentCourseData,
        overwriteHistoryCourseData: overwriteHistoryCourseData,
        shouldInactivateRecords: shouldInactivateRecords,
        shouldOverwriteCourseName: shouldOverwriteCourseName,
        doNotOverwriteEmail: doNotOverwriteEmail,
        includeMappedRole: includeMappedRole,
        doNotMarkCleverStudentInactive: props.includeMarkCleverStudentInactiveRule
          ? doNotMarkCleverStudentInactive
          : undefined,
        doNotInactivateCleverRoles: props.doNotInactivateCleverRoles ? doNotInactivateCleverRoles : undefined,
      },
    });
    setOriginalConfig(data);
    setFlagCallees.forEach((fn, index) => {
      fn(data.importRules?.[flagNames[index]]);
    });
  };

  const fetchAvailableSchoolMapping = async () => {
    try {
      const { data } = await apiClient.get('/data-ingest/sis/parameter-group/schoolMapping');
      if (data.schoolMapping) {
        const mapping = data.schoolMapping.reduce((acc, value) => {
          const { sisId, nid, disabled } = value;
          acc[nid] = { nid, sisId, enabled: !disabled };
          return acc;
        }, {});
        const res = await apiClient.get('/highschools/v2?byType=district&includeInactive=true');
        const navSchools: NavSchoolsType[] = res.data.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));
        const mappedSchools = Object.values(mapping)
          .filter((mappedSchool: { sisId: string }) => !!mappedSchool.sisId)
          .map((mappedSchool: { nid: string }) => mappedSchool.nid);
        setAvailableMappedSchools(
          navSchools
            .filter((navSchool) => mappedSchools.includes(navSchool.nid))
            .sort((a, b) => (a.isAccount > b.isAccount ? 1 : a.isAccount < b.isAccount ? -1 : 0)),
        );
      }
    } catch (error) {
      showNotification(
        NotificationTypes.error,
        'Error Getting School Mappings',
        'Failure in getting data from server.',
      );
    }
  };

  const dynamicFlagSetter = (value: boolean | string[], flagName: string) => {
    setFlagCallees[flagNames.indexOf(flagName)](flagName === 'doNotOverwriteEmail' ? !value : value);
  };

  useEffect(() => {
    isMountedRef.current = true;

    void Promise.all([
      fetchIngress(),
      fetchConfig(),
      fetchAvailableSchoolMappings ? fetchAvailableSchoolMapping() : null,
    ]).finally(() => {
      if (isMountedRef.current) {
        setPageLoading(false);
        setHideFooterComp(false);
      }
    });

    return () => (isMountedRef.current = false);
  }, []);

  useEffect(() => {
    isMountedRef.current = true;
    void Promise.all([
      integrationContext.isCleverIntegration && type === 'staff-records' && Object.keys(translateConfig).length
        ? fetchNavianceRoles()
        : null,
    ]);

    return () => (isMountedRef.current = false);
  }, [translateConfig]);

  return (
    <Wizard
      steps={props.steps}
      currentStep={props.currentStep}
      continueFn={() => save(true)}
      previousFn={props.previousFn}
      saveFn={() => save()}
      cancelFn={props.cancelFn}
      saveAndCloseFn={saveAndClose}
      changeStepFn={props.changeStepFn}
      pageLoading={pageLoading}
      hideHeaderButtons={true}
      hideFooter={disableFooter}
    >
      <>
        {props.includeSchools && (
          <Row>
            <Col span={24}>
              <SelectSchools
                inputSchools={schools}
                inputRadio={schools.length === 0 ? 0 : 1}
                updateSchools={(schools) => {
                  setSchools(schools);
                }}
                showRadio={showRadio}
                availableMappedSchools={availableMappedSchools}
              ></SelectSchools>
            </Col>
          </Row>
        )}
        <br />
        <Row>
          <Col span={24}>
            <DataUpdateFrequency frequency={frequency} setFrequency={setFrequency} frequencies={frequencies} />
          </Col>
        </Row>
        <br />
        <Row>
          {(props.includeInactivateRule || props.includeOverwriteRule || props.includeOverwriteEmailRule) && (
            <Col span={24}>
              <h2 className="infoHeading uploadHeading">Import rules</h2>
            </Col>
          )}
        </Row>
        <Row gutter={[0, 16]}>
          {props.includeInactivateRule && (
            <Col span={24}>
              <Checkbox
                checked={shouldInactivateRecords}
                data-test-id="make_navrecord_inactive"
                onChange={(e) => {
                  dynamicFlagSetter(e.target.checked, 'shouldInactivateRecords');
                }}
              >
                Make Naviance records inactive if not in{' '}
                {`${integrationContext.isCleverIntegration
                    ? `Clever${type === 'staff-records' ? ', except:' : ''}`
                    : 'SIS'
                  }`}
              </Checkbox>
              <br />
              {shouldInactivateRecords && integrationContext.isCleverIntegration && !!cleverRoleOptions.length && (
                <Checkbox.Group
                  style={{ width: '50%', marginLeft: '40px' }}
                  data-test-id="make_navrecord_inactive_except"
                  value={doNotInactivateCleverRoles}
                  onChange={(checkedValues) => {
                    dynamicFlagSetter(checkedValues as string[], 'doNotInactivateCleverRoles');
                  }}
                >
                  <Row>
                    {cleverRoleOptions.map((option) => (
                      <Col span={8} key={option.value}>
                        <Checkbox value={option.label}>{option.label}</Checkbox>
                      </Col>
                    ))}
                  </Row>
                </Checkbox.Group>
              )}
            </Col>
          )}
          {props.includeOverwriteRule && (
            <Col span={24}>
              <Checkbox
                checked={overwriteNavData}
                data-test-id="overwrite_data_ifblank"
                onChange={(e) => {
                  dynamicFlagSetter(e.target.checked, 'overwriteNavData');
                }}
              >
                Overwrite data in Naviance if imported data is blank
              </Checkbox>
            </Col>
          )}
          {props.includeCurrentCourseData && (
            <Col span={24}>
              <Checkbox
                data-test-id="overwrite_data_from_sis"
                checked={overwriteCurrentCourseData}
                onChange={(e) => {
                  dynamicFlagSetter(e.target.checked, 'overwriteCurrentCourseData');
                }}
              >
                Overwrite current course data in Naviance for students in SIS
              </Checkbox>
            </Col>
          )}
          {props.includeHistoryCourseData && (
            <Col span={24}>
              <Checkbox
                data-test-id="overwrite_data_from_sis"
                checked={overwriteHistoryCourseData}
                onChange={(e) => {
                  dynamicFlagSetter(e.target.checked, 'overwriteHistoryCourseData');
                }}
              >
                Overwrite data in Naviance with data from the SIS
              </Checkbox>
            </Col>
          )}
          {props.includeOverwriteEmailRule && (
            <Col span={24}>
              <Checkbox
                data-test-id="doNotOverwriteEmail"
                checked={!doNotOverwriteEmail}
                onChange={(e) => {
                  dynamicFlagSetter(e.target.checked, 'doNotOverwriteEmail');
                }}
              >
                Overwrite email in Naviance (if email is imported)
              </Checkbox>
            </Col>
          )}
          {props.includeMarkCleverStudentInactiveRule && (
            <Col span={24}>
              <Checkbox
                data-test-id="doNotMarkCleverStudentInactive"
                checked={doNotMarkCleverStudentInactive}
                onChange={(e) => {
                  dynamicFlagSetter(e.target.checked, 'doNotMarkCleverStudentInactive');
                }}
              >
                Keep grade 12 and postgraduate students active in Naviance when removed from Clever.&nbsp;&nbsp;
                <Tooltip placement="bottomLeft" title={text}>
                  <QuestionCircleOutlined />
                </Tooltip>
              </Checkbox>
            </Col>
          )}
          {props.includeOverwriteCourseNameRule && (
            <Col span={24}>
              <Checkbox
                checked={shouldOverwriteCourseName}
                data-test-id="overwrite_navCourseName"
                onChange={(e) => {
                  dynamicFlagSetter(e.target.checked, 'shouldOverwriteCourseName');
                }}
              >
                Overwrite course name in Naviance
              </Checkbox>
            </Col>
          )}
          {type === 'staff-records' && props.includeOverwriteRoleRule && (
            <Col span={24}>
              <Checkbox
                checked={includeMappedRole}
                data-test-id="include_mapped_role"
                onChange={(e) => {
                  dynamicFlagSetter(e.target.checked, 'includeMappedRole');
                }}
              >
                Include only mapped roles in the log file
              </Checkbox>
            </Col>
          )}
        </Row>
      </>
    </Wizard>
  );
};

export default SisSyncSettings;
