/* eslint-disable @typescript-eslint/no-explicit-any */
import Table, { ColumnsType } from 'antd/lib/table';
import Title from 'antd/lib/typography/Title';
import * as React from 'react';
import { IntegrationContext } from '../../utils/context';
import { unixTimeToDateTime } from '../../utils/utils';

interface StudentReconcileRow {
  source: string;
  lastName: string;
  firstName: string;
  schoolId: string;
  navStudentId: string;
  classYear: string;
  birthDate: string;
  status: string;
  numVisits: string;
}
interface StaffReconcileRow {
  source: string;
  lastName: string;
  firstName: string;
  schoolId: string;
  employeeId: string;
  staffEmail: string;
  role: string;
  userName: string;
  status: string;
  numVisits: string;
  lastVisit: string;
  teacherRecommendations: string;
}
interface ParentReconcileRow {
  source: string;
  studentId: string;
  parentId: string;
  lastName: string;
  firstName: string;
  email: string;
}
interface SisReconcileProps {
  student?: Record<string, any>;
  navStudents?: Record<string, any>[];
  staff?: Record<string, any>;
  navStaffs?: Record<string, any>[];
  parent?: Record<string, any>;
  navParents?: Record<string, any>[];
  updateReconciled: (
    update: Record<
      string,
      { student: string; create: boolean } | { staff: string; create: boolean } | { parent: string; create: boolean }
    >,
  ) => void;
  matchedRows: Record<
    string,
    { student: string; create: boolean } | { staff: string; create: boolean } | { parent: string; create: boolean }
  >;
  setMatchedRows: (
    matchedRows: Record<
      string,
      { student: string; create: boolean } | { staff: string; create: boolean } | { parent: string; create: boolean }
    >,
  ) => void;
  isDisabled: boolean;
  dataType: string;
  rolesMap: Record<string, string>;
}

const SisReconcileEntity = ({
  student,
  navStudents,
  staff,
  navStaffs,
  parent,
  navParents,
  updateReconciled,
  matchedRows,
  setMatchedRows,
  isDisabled,
  dataType,
  rolesMap,
}: SisReconcileProps): React.ReactElement => {
  const [toReconcile, setToReconcile] = React.useState([]);
  const [matchedRow, setMatchedRow] = React.useState(
    dataType === 'students' || dataType === 'alumni'
      ? matchedRows[student?.dcid] ?? { student: null, create: false }
      : dataType === 'parent-records'
      ? matchedRows[parent?.personDcid] ?? { parent: null, create: false }
      : matchedRows[`${staff?.dcid}-${staff?.entityId}-${staff?.role}`] ?? { staff: null, create: false },
  );
  const { isCleverIntegration } = React.useContext(IntegrationContext);
  const data = [];
  React.useEffect(() => {
    if (dataType === 'students' || dataType === 'alumni') {
      data.push(
        {
          source: isCleverIntegration ? 'Clever' : 'SIS',
          lastName: student?.lastName,
          firstName: student?.firstName,
          schoolId: student?.Naviance_School_ID,
          hsStudentId: student?.hsStudentId,
          classYear: student?.classYear ? `${student?.classYear}` : '',
          birthDate: student?.birthDate ? `${student?.birthDate}` : '',
          status: (student?.student as any)?.isActive ? 'Active' : 'Inactive',
          numVisits: '',
          key: `${student?.dcid}-sis`,
        },
        ...navStudents.map((navStudent) => ({
          id: navStudent?.id,
          source: 'Naviance',
          lastName: navStudent?.lastName,
          firstName: navStudent?.firstName,
          schoolId: navStudent?.schoolId,
          hsStudentId: navStudent?.hsStudentId,
          classYear: navStudent?.classYear ? `${navStudent?.classYear}` : '',
          birthDate: navStudent?.birthDate ? `${navStudent?.birthDate}` : '',
          status: navStudent?.isActive ? 'Active' : 'Inactive',
          numVisits: (navStudent?.studentUser as any)?.numVisits || 0,
          key: `${student?.dcid}-${navStudent?.id}`,
        })),
      );
    } else if (dataType === 'parent-records') {
      data.push(
        {
          source: isCleverIntegration ? 'Clever' : 'SIS',
          studentId: parent.student_links.map((student_link) => student_link.student_dcid).toString(),
          parentId: parent?.hsParentId,
          lastName: parent?.lastName,
          firstName: parent?.firstName,
          email: parent?.email,
          key: `${parent?.personDcid}-sis`,
        },
        ...navParents.map((navParent) => ({
          source: 'Naviance',
          studentId: navParent?.studentParentLinks
            .map((studentParentLink) => studentParentLink.fk_student_id)
            .toString(),
          parentId: navParent?.id,
          lastName: navParent?.lastName,
          firstName: navParent?.firstName,
          email: navParent?.email,
          key: `${parent?.personDcid}-${navParent?.id}`,
        })),
      );
    } else {
        data.push(
          {
            source: isCleverIntegration ? 'Clever' : 'SIS',
            cleverId: staff?.cleverId,
            lastName: staff?.lastName,
            firstName: staff?.firstName,
            schoolId: staff?.Naviance_School_ID,
            employeeId: staff?.employeeIdExternal,
            email: staff?.email,
            role: isCleverIntegration ? 'N/A' : staff?.role,
            userName: staff.userName,
            status: (staff?.staff as any)?.isActive ? 'Active' : 'Inactive',
            numVisits: '-',
            teacherRecommendations: '-',
            lastVisit: '-',
            key: `${staff?.dcid}-${staff?.entityId}-${staff?.role}-sis`,
          },
          ...navStaffs.map((navStaff) => ({
            id: navStaff?.id,
            cleverId: navStaff?.highSchoolUser?.cleverId,
            source: 'Naviance',
            lastName: navStaff?.lastName,
            firstName: navStaff?.firstName,
            schoolId: navStaff?.entityId,
            employeeId: navStaff?.employeeIdExternal,
            email: navStaff?.email,
            role: rolesMap[Number(navStaff?.highSchoolUser?.role)],
            userName: navStaff?.highSchoolUser?.userName,
            status: navStaff?.isActive ? 'Active' : 'Inactive',
            numVisits: (navStaff?.highSchoolUser as any)?.numVisits || 0,
            teacherRecommendations: navStaff?.highSchoolUser?.teacherRecommendations?.length || 0,
            lastVisit:
              !navStaff?.highSchoolUser?.lastVisit || isNaN(navStaff?.highSchoolUser?.lastVisit)
                ? '-'
                : unixTimeToDateTime(navStaff?.highSchoolUser?.lastVisit),
            key: `${isCleverIntegration ? staff?.cleverId : staff?.dcid}-${staff?.entityId}-${staff?.role}-${
              navStaff?.id
            }`,
          })),
        );
    }
    setToReconcile(data);
  }, []);

  React.useEffect(() => {
    if (dataType === 'students' || dataType === 'alumni') {
      if (student && Object.keys(student).length) {
        updateReconciled({ [student.dcid]: matchedRow });
      }
    } else if (dataType === 'parent-records') {
      if (parent && Object.keys(parent).length) {
        updateReconciled({ [parent.personDcid]: matchedRow });
      }
    } else {
      if (staff && Object.keys(staff).length) {
        updateReconciled({ [`${staff?.dcid}-${staff?.entityId}-${staff?.role}`]: matchedRow });
      }
    }
  }, [matchedRow]);

  const DataImportTableColumnsStudents: ColumnsType<StudentReconcileRow> = [
    {
      title: <Title level={5}>Source</Title>,
      dataIndex: 'source',
      key: 'source',
      width: '10%',
    },
    {
      title: <Title level={5}>Last Name</Title>,
      dataIndex: 'lastName',
      key: 'lastName',
      width: '10%',
    },
    {
      title: <Title level={5}>First Name</Title>,
      dataIndex: 'firstName',
      key: 'firstName',
      width: '10%',
    },
    {
      title: <Title level={5}>School ID</Title>,
      dataIndex: 'schoolId',
      key: 'schoolId',
      width: '10%',
    },
    {
      title: <Title level={5}>Student ID</Title>,
      dataIndex: 'hsStudentId',
      key: 'hsStudentId',
      width: '10%',
    },
    {
      title: <Title level={5}>Class Year</Title>,
      dataIndex: 'classYear',
      key: 'classYear',
      width: '10%',
    },
    {
      title: <Title level={5}>Birth Date</Title>,
      dataIndex: 'birthDate',
      key: 'birthDate',
      width: '10%',
    },
    {
      title: <Title level={5}>Status</Title>,
      dataIndex: 'status',
      key: 'status',
      width: '8%',
    },
    {
      title: <Title level={5}>Visits</Title>,
      dataIndex: 'numVisits',
      key: 'numVisits',
      width: '8%',
    },
    {
      title: <Title level={5}>Match Records</Title>,
      width: '14%',
      hidden: isDisabled,
      render: (row: { source: string; key: any; id: any }) => {
        if (row.source === 'Naviance') {
          if (!matchedRow.create) {
            if (!(matchedRow as any).student) {
              return (
                <button
                  className="button"
                  data-test-id={`match_btn-${row.key}`}
                  disabled={Object.values(matchedRows).some((item) => item.student === row.id)}
                  onClick={() => {
                    setMatchedRow({ student: row.id, create: false });
                    const upDatedMatchedRows = { ...matchedRows };
                    upDatedMatchedRows[student.dcid] = { student: row.id, create: false };
                    setMatchedRows(upDatedMatchedRows);
                  }}
                >
                  Match
                </button>
              );
            }
            if ((matchedRow as any).student && `${(matchedRow as any).student}` === `${row.id}`) {
              return (
                <button
                  className="button"
                  data-test-id={`cancel_match_btn-${row.key}`}
                  onClick={() => {
                    setMatchedRow({ student: null, create: false });
                    const upDatedMatchedRows = { ...matchedRows };
                    delete upDatedMatchedRows[student.dcid];
                    setMatchedRows(upDatedMatchedRows);
                  }}
                >
                  Cancel Match
                </button>
              );
            }
          }
        } else {
          if (!(matchedRow as any).student) {
            if (matchedRow.create) {
              return (
                <button
                  className="button"
                  data-test-id={`cancel_create_btn-${row.key}`}
                  onClick={() => {
                    setMatchedRow({ student: null, create: false });
                    const upDatedMatchedRows = { ...matchedRows };
                    delete upDatedMatchedRows[student.dcid];
                    setMatchedRows(upDatedMatchedRows);
                  }}
                >
                  Cancel Create
                </button>
              );
            } else {
              return (
                <button
                  className="button"
                  data-test-id={`create_btn-${row.key}`}
                  onClick={() => {
                    setMatchedRow({ student: null, create: true });
                    const upDatedMatchedRows = { ...matchedRows };
                    upDatedMatchedRows[student.dcid] = { student: null, create: true };
                    setMatchedRows(upDatedMatchedRows);
                  }}
                >
                  Create
                </button>
              );
            }
          }
        }
        return '';
      },
    },
  ].filter((item) => !item.hidden);

  const DataImportTableColumnsStaffs: ColumnsType<StaffReconcileRow> = [
    {
      title: <Title level={5}>Source</Title>,
      dataIndex: 'source',
      key: 'source',
      width: 100,
      fixed: 'left',
    },
    {
      title: <Title level={5}>Last Name</Title>,
      dataIndex: 'lastName',
      key: 'lastName',
      width: 250,
    },
    {
      title: <Title level={5}>First Name</Title>,
      dataIndex: 'firstName',
      key: 'firstName',
      width: 250,
    },
    {
      title: <Title level={5}>School ID</Title>,
      dataIndex: 'schoolId',
      key: 'schoolId',
      width: 200,
    },
    {
      title: <Title level={5}>Employee ID</Title>,
      dataIndex: 'employeeId',
      key: 'employeeId',
      width: 200,
    },
    ...(isCleverIntegration
      ? [
          {
            title: <Title level={5}>Clever ID</Title>,
            dataIndex: 'cleverId',
            key: 'cleverId',
            width: 250,
          },
          {
            title: <Title level={5}>Role</Title>,
            dataIndex: 'role',
            key: 'role',
            width: 250,
          },
          {
            title: <Title level={5}>Last Visit</Title>,
            dataIndex: 'lastVisit',
            key: 'lastVisit',
            width: 120,
          },
          {
            title: <Title level={5}>Teacher Recommendations</Title>,
            dataIndex: 'teacherRecommendations',
            key: 'teacherRecommendations',
            width: 250,
          },
        ]
      : [
          {
            title: <Title level={5}>Staff Email</Title>,
            dataIndex: 'email',
            key: 'email',
            width: 150,
          },
          {
            title: <Title level={5}>Role</Title>,
            dataIndex: 'role',
            key: 'role',
            width: 200,
          },
          {
            title: <Title level={5}>UserName</Title>,
            dataIndex: 'userName',
            key: 'userName',
            width: 150,
          },
        ]),
    {
      title: <Title level={5}>Status</Title>,
      dataIndex: 'status',
      key: 'status',
      width: 150,
    },
    {
      title: <Title level={5}>Visits</Title>,
      dataIndex: 'numVisits',
      key: 'numVisits',
      width: 150,
    },
    {
      title: <Title level={5}>Match</Title>,
      width: 180,
      hidden: isDisabled,
      fixed: 'right',
      render: (row) => {
        if (row.source === 'Naviance') {
          if (!matchedRow.create) {
            if (!(matchedRow as any).staff) {
              return (
                <button
                  className="button"
                  data-test-id={`match_btn-${row.key}`}
                  disabled={Object.values(matchedRows).some((item) => item.staff === row.id)}
                  onClick={() => {
                    setMatchedRow({ staff: row.id, create: false });
                    const upDatedMatchedRows = { ...matchedRows };
                    upDatedMatchedRows[`${staff?.dcid}-${staff?.entityId}-${staff?.role}`] = {
                      staff: row.id,
                      create: false,
                    };
                    setMatchedRows(upDatedMatchedRows);
                  }}
                >
                  Match
                </button>
              );
            }
            if ((matchedRow as any).staff && `${(matchedRow as any).staff}` === `${row.id}`) {
              return (
                <button
                  className="button"
                  data-test-id={`cancel_match_btn-${row.key}`}
                  onClick={() => {
                    setMatchedRow({ staff: null, create: false });
                    const upDatedMatchedRows = { ...matchedRows };
                    delete upDatedMatchedRows[`${staff?.dcid}-${staff?.entityId}-${staff?.role}`];
                    setMatchedRows(upDatedMatchedRows);
                  }}
                >
                  Cancel Match
                </button>
              );
            }
          }
        } else {
          if (!(matchedRow as any).staff) {
            if (matchedRow.create) {
              return (
                <button
                  className="button"
                  data-test-id={`cancel_create_btn-${row.key}`}
                  onClick={() => {
                    setMatchedRow({ staff: null, create: false });
                    const upDatedMatchedRows = { ...matchedRows };
                    delete upDatedMatchedRows[`${staff?.dcid}-${staff?.entityId}-${staff?.role}`];
                    setMatchedRows(upDatedMatchedRows);
                  }}
                >
                  Cancel Create
                </button>
              );
            } else {
              return (
                <button
                  className="button"
                  data-test-id={`create_btn-${row.key}`}
                  onClick={() => {
                    setMatchedRow({ staff: null, create: true });
                    const upDatedMatchedRows = { ...matchedRows };
                    upDatedMatchedRows[`${staff?.dcid}-${staff?.entityId}-${staff?.role}`] = {
                      staff: null,
                      create: true,
                    };
                    setMatchedRows(upDatedMatchedRows);
                  }}
                >
                  Create
                </button>
              );
            }
          }
        }
        return '';
      },
    },
  ].filter((item) => !item.hidden);

  const DataImportTableColumnsParents: ColumnsType<ParentReconcileRow> = [
    {
      title: <Title level={5}>Source</Title>,
      dataIndex: 'source',
      key: 'source',
      width: '10%',
    },
    {
      title: <Title level={5}>Student ID</Title>,
      dataIndex: 'studentId',
      key: 'studentId',
      width: '10%',
    },
    {
      title: <Title level={5}>Parent ID</Title>,
      dataIndex: 'parentId',
      key: 'parentId',
      width: '10%',
    },
    {
      title: <Title level={5}>Last Name</Title>,
      dataIndex: 'lastName',
      key: 'lastName',
      width: '10%',
    },
    {
      title: <Title level={5}>First Name</Title>,
      dataIndex: 'firstName',
      key: 'firstName',
      width: '10%',
    },
    {
      title: <Title level={5}> Parents Email</Title>,
      dataIndex: 'email',
      key: 'email',
      width: '10%',
    },
    {
      title: <Title level={5}>Match Records</Title>,
      width: '14%',
      hidden: isDisabled,
      render: (row) => {
        if (row.source === 'Naviance') {
          if (!matchedRow.create) {
            if (!(matchedRow as any).parent) {
              return (
                <button
                  className="button"
                  data-test-id={`match_btn-${row.key}`}
                  disabled={Object.values(matchedRows).some((item) => item.parent === row.parentId)}
                  onClick={() => {
                    setMatchedRow({ parent: row.parentId, create: false });
                    const upDatedMatchedRows = { ...matchedRows };
                    upDatedMatchedRows[parent.personDcid] = { parent: row.parentId, create: false };
                    setMatchedRows(upDatedMatchedRows);
                  }}
                >
                  Match
                </button>
              );
            }
            if ((matchedRow as any).parent && `${(matchedRow as any).parent}` === `${row.parentId}`) {
              return (
                <button
                  className="button"
                  data-test-id={`cancel_match_btn-${row.key}`}
                  onClick={() => {
                    setMatchedRow({ parent: null, create: false });
                    const upDatedMatchedRows = { ...matchedRows };
                    delete upDatedMatchedRows[parent.personDcid];
                    setMatchedRows(upDatedMatchedRows);
                  }}
                >
                  Cancel Match
                </button>
              );
            }
          }
        } else {
          if (!(matchedRow as any).parent) {
            if (matchedRow.create) {
              return (
                <button
                  className="button"
                  data-test-id={`cancel_create_btn-${row.key}`}
                  onClick={() => {
                    setMatchedRow({ parent: null, create: false });
                    const upDatedMatchedRows = { ...matchedRows };
                    delete upDatedMatchedRows[parent.personDcid];
                    setMatchedRows(upDatedMatchedRows);
                  }}
                >
                  Cancel Create
                </button>
              );
            } else {
              return (
                <button
                  className="button"
                  data-test-id={`create_btn-${row.key}`}
                  onClick={() => {
                    setMatchedRow({ parent: null, create: true });
                    const upDatedMatchedRows = { ...matchedRows };
                    upDatedMatchedRows[parent.personDcid] = { parent: null, create: true };
                    setMatchedRows(upDatedMatchedRows);
                  }}
                >
                  Create
                </button>
              );
            }
          }
        }
        return '';
      },
    },
  ].filter((item) => !item.hidden);

  return (
    <div>
      <h1></h1>
      <Table
        rowKey="key"
        columns={
          dataType === 'students' || dataType === 'alumni'
            ? DataImportTableColumnsStudents
            : dataType === 'parent-records'
            ? DataImportTableColumnsParents
            : DataImportTableColumnsStaffs
        }
        dataSource={toReconcile}
        data-test-id={
          dataType === 'students' || dataType === 'alumni'
            ? 'student_to_reconcile'
            : dataType === 'parent-records'
            ? 'parent_to_reconcile'
            : 'staff_to_reconcile'
        }
        pagination={false}
        rowClassName={(_: any, ind: number) => (ind === 0 ? 'sisRow' : '')}
        scroll={{ x: 1300}}
      ></Table>
      <br />
    </div>
  );
};

export default SisReconcileEntity;
