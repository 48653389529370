import { showNotification, NotificationTypes } from '../components/Notifications';
import apiClient from './apiClient';
import { BASE_PHOTO_URL } from './constants';

export interface DeactivatedStudent {
    id: number;
    firstName: string;
    lastName: string;
    highSchoolName: string;
    highSchoolStudentId: string;
    photoUrl: string;
}

let debounceTimer = null;
export const debounceSearch = (
  term: string,
  setLoading: (loading: boolean) => void,
  classYearBasedDeactivatedStudents: { [key: number]: number[] },
  selectedClassYear: number,
  districtId: string,
  schoolMap: { [key: string]: string },
  setTotalDeactivatedStudents: (totalDeactivatedStudents: number) => void,
  setDeactivatedStudents: (deactivatedStudents: DeactivatedStudent[]) => void,
): void => {
  if (debounceTimer) {
    clearTimeout(debounceTimer);
  }
  debounceTimer = setTimeout(
    () =>
      fetchDeactivatedStudents(
        term,
        setLoading,
        classYearBasedDeactivatedStudents,
        selectedClassYear,
        districtId,
        schoolMap,
        setTotalDeactivatedStudents,
        setDeactivatedStudents,
      ),
    3000,
  );
};

export const fetchDeactivatedStudents = async (
  searchtext: string,
  setLoading: (loading: boolean) => void,
  classYearBasedDeactivatedStudents: { [key: number]: number[] },
  selectedClassYear: number,
  districtId: string,
  schoolMap: { [key: string]: string },
  setTotalDeactivatedStudents: (totalDeactivatedStudents: number) => void,
  setDeactivatedStudents: (deactivatedStudents: any) => void,
  page = 1,
  size = 10,
): Promise<void> => {
  try {
    setLoading(true);

    const { data } = await apiClient.post('/students/studentsByIdsAndSearchCriteria', {
      studentIds: classYearBasedDeactivatedStudents[selectedClassYear],
      includePhoto: true,
      districtId,
      limit: size,
      projections: ['firstName', 'lastName', 'highSchoolStudentId', 'highschoolId'],
      page,
      ...(searchtext ? { search: searchtext } : {}),
    });

    const tempDeactivatedStudents: DeactivatedStudent[] = data.data.map((student) => ({
      id: student.id,
      firstName: student.firstName as string,
      lastName: student.lastName as string,
      highSchoolName: schoolMap[student.highschoolId] ?? '',
      highSchoolStudentId: student.highSchoolStudentId as string,
      photoUrl:
        student.photo.length > 0
          ? `${BASE_PHOTO_URL}?id=${student.id}&type=student&h=${student.photo[0]?.fileName}`
          : '',
    }));

    setTotalDeactivatedStudents(data.totalItems);
    setDeactivatedStudents(tempDeactivatedStudents);
  } catch (error) {
    console.log(error);
    showNotification(
      NotificationTypes.error,
      'Error Getting Deactivated Students',
      'Failure in getting data from server.',
    );
  }
  setLoading(false);
};
