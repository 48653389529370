import { Badge, Tabs } from 'antd';
import React from 'react';
import ClassDetails, { classDetailsData } from './ClassDetails/ClassDetails';
import { SisStudentReconciled, SisStaffReconciled, SisParentReconciled, SisStudentJobSummary } from '../../../../../../libs/common-interfaces';
import PSDataImportJobReconcile from './PSDataImportJobReconcile';

interface SummaryTabsProps {
    classDetailsData: classDetailsData[];
    activeKey: string;
    setActiveKey: (updated: string) => void;
    jobMeta: SisStudentJobSummary;
    setReconciled: (updatedReconciled: SisStudentReconciled | SisStaffReconciled | SisParentReconciled) => void;
    postReconciled: () => void;
    reconcileDisabled: boolean;
    setReconcileDisabled: (updated: boolean) => void;
    dataType: string;
}

const styles = {
    badge: {
        backgroundColor: "#1890ff",
        marginLeft: "8px",
        marginRight: "8px",
    },
}

const SummaryTabs = ({
    classDetailsData,
    activeKey,
    setActiveKey,
    jobMeta,
    setReconciled,
    postReconciled,
    reconcileDisabled,
    setReconcileDisabled,
    dataType,
}: SummaryTabsProps): React.ReactElement => {
    return (
        <Tabs
            activeKey={activeKey}
            onChange={(updateActiveKey) => setActiveKey(updateActiveKey)}
            items={[
                {
                    key: '1',
                    label: 'Records by class year',
                    children: <ClassDetails dataSource={classDetailsData} showTitle={false}></ClassDetails>,
                },
                ...(jobMeta?.recordsSkipped ?
                    [{
                        key: '2',
                        label: <>
                            Errors
                            <Badge
                                count={jobMeta?.recordsSkipped}
                                style={styles.badge}
                                overflowCount={99999}
                                showZero
                            />
                        </>,
                        children: 'Errors',
                    }] : []),
                ...(jobMeta?.recordsNotSkipped ? [{
                    key: '3',
                    label: <>
                        Warnings
                        <Badge
                            count={`${(jobMeta?.recordsNotSkipped || 0)}`}
                            style={styles.badge}
                            overflowCount={99999}
                            showZero
                        />
                    </>,
                    children: 'Warnings',
                }] : []),
                ...(jobMeta?.status !== 'FAILED' &&
                    jobMeta?.mostRecent &&
                    jobMeta?.toReconcile &&
                    jobMeta?.isReconcilable
                    ?
                    [{
                        key: '4',
                        label: <>
                            Reconcile
                            <Badge
                                count={`${(jobMeta?.toReconcile || 0)}`}
                                style={styles.badge}
                                overflowCount={99999}
                                showZero
                            />
                        </>,
                        children: (
                            <PSDataImportJobReconcile
                                jobGuid={jobMeta.id}
                                update={(updated) => setReconciled(updated)}
                                post={() => postReconciled()}
                                isTestImport={jobMeta?.isTestImport}
                                reconcileDisabled={reconcileDisabled}
                                setReconcileDisabled={setReconcileDisabled}
                                dataType={dataType}
                            />
                        )
                    }]
                    : []
                )
            ]}
        />
    );
}

export default SummaryTabs;
